//import {useState} from 'react';
//import logo from './logo.svg';
import { useState } from 'react';
import { useEffect } from 'react';
import React from 'react';
import './App.css';
import {Link, Route, Routes } from 'react-router-dom';

// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.js</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
//   );
// }

// export default App;



function App() {

  var [isMobile,setIsMobile]=useState(window.innerHeight>window.innerWidth);

  useEffect(()=>{
    
    const screenChange=()=>{
      setIsMobile(window.innerHeight>window.innerWidth);
    }

    window.addEventListener('resize',screenChange);

    return ()=>{window.removeEventListener('resize',screenChange);};
  },[]);

  const [menuItemsVisiblity,setMenuItemsVisibility]=useState('menuItemsHidden');

  return (
    <div className="positionAbsolute W100vw backgroundBlack colorWhite">
            <div className='displayFlex backgroundWhite'>
        <div className={isMobile?'W90vw displayFlex alignItemsCenter':'displayFlex alignItemsCenter'}>
        <Link className='colorBlack textDecorationNone navMenuItem' to='/'>AlterUX</Link>
        </div>
        {!isMobile && <NavigationBar/>}
        {isMobile && <NavigationMenu menuItemsVisiblity={menuItemsVisiblity} setMenuItemsVisibility={setMenuItemsVisibility}/>}
        </div>

        <NavigationMenuItems menuItemsVisiblity={menuItemsVisiblity}/>

        <Routes>
          <Route path='/' element={<Home/>}/>
          <Route path='/contact' element={<Contact/>}/>
          <Route path='/about' element={<About/>}/>
          <Route path='/learn' element={<Learn/>}/>
          <Route path='/projects' element={<Projects/>}/>
          <Route path='/upload' element={<Upload/>}/>
        </Routes>
    </div>
  );
}

export default App;


function NavigationBar(){
return (
  <nav className='backgroundWhite'>
    <Link className='colorBlack textDecorationNone navMenuItem' to='/'>Home</Link>
    <Link className='colorBlack textDecorationNone navMenuItem' to='/contact'>Contact</Link>
    <Link className='colorBlack textDecorationNone navMenuItem' to='/about'>About</Link>
    <Link className='colorBlack textDecorationNone navMenuItem' to='/learn'>Learn</Link>
    <Link className='colorBlack textDecorationNone navMenuItem' to='/projects'>Projects</Link>
  </nav>
);
}

function Home(){
  return(<div className='H100vh W100vw displayFlex justifyContentCenter alignItemsCenter'><h1>Welcome to AlterUX!</h1></div>);
}
function Contact(){
  return(<div className='H100vh W100vw displayFlex justifyContentCenter alignItemsCenter'><h1><a className='colorWhite textDecorationNone' href='https://www.linkedin.com/in/shivarajkumar-m'>LinkedIn</a></h1></div>);
}
function About(){
  return(<div className='H100vh W100vw displayFlex justifyContentCenter alignItemsCenter'><h1>This website was designed by Shivarajkumar Moria</h1></div>);
}
function Learn(){
  return(
  <div className='H100vh W100vw displayFlex justifyContentCenter alignItemsCenter'>
    <div>
  <h1>Learn</h1>
  <p>Add a page that teaches</p>
  </div>
  </div>
  );
}
function Projects(){

  return(
  <div className='H100vh W100vw displayFlex justifyContentCenter alignItemsCenter'>
    <div>
  <h1>Cipher</h1>
  <p>A project aimed at securing private data, by encrypting data entered by a user.</p>
  <br/><br/>
  <a href="http://alterux.online/cipher">Click here to visit</a>
  </div>
  </div>
  );
}

function Upload(){
  
  return(
    <div className='H100vh W100vw displayFlex justifyContentCenter alignItemsCenter'>
    <div>
      <form method='post' action='/uploadfile' enctype='multipart/form-data'>
      <input type='file' name='fileName'></input>
      <input type='password' name='passwordName'></input>
      <input type='submit' value='Upload'></input>
      </form>
  </div>
  </div>
  );

}



function NavigationMenu({menuItemsVisiblity,setMenuItemsVisibility}){

  function expandMenu(){
    menuItemsVisiblity==='menuItemsHidden'?setMenuItemsVisibility('menuItemsVisible'):setMenuItemsVisibility('menuItemsHidden');
  }

  return (
    <div>
        <button onClick={expandMenu} className='menuButton'><img className='menuImage' src='menuIcon.svg' alt='Menu'></img></button>
        </div>
  );
}


function NavigationMenuItems({menuItemsVisiblity}){
  console.log(menuItemsVisiblity);
  return (
  <nav className={menuItemsVisiblity+' backgroundWhite displayFlex flexDirectionColumn textAlignCenter'}>
    <Link className={menuItemsVisiblity+' colorBlack textDecorationNone navMenuItem'} to='/'>Home</Link>
    <Link className={menuItemsVisiblity+' colorBlack textDecorationNone navMenuItem'} to='/contact'>Contact</Link>
    <Link className={menuItemsVisiblity+' colorBlack textDecorationNone navMenuItem'} to='/about'>About</Link>
    <Link className={menuItemsVisiblity+' colorBlack textDecorationNone navMenuItem'} to='/learn'>Learn</Link>
    <Link className={menuItemsVisiblity+' colorBlack textDecorationNone navMenuItem'} to='/projects'>Projects</Link>
  </nav>);
}